/**
 * THIS FILE IS AUTO-GENERATED. DO NOT MODIFY! */
export enum Permission {
    Disable_User = "Disable_User",
    Enable_User = "Enable_User",
    Create_User = "Create_User",
    Assign_Leads_To_Salesperson = "Assign_Leads_To_Salesperson",
    Assign_User_Roles = "Assign_User_Roles",
    Assign_User_Permissions = "Assign_User_Permissions",
    Bulk_Product_Import = "Bulk_Product_Import",
    Create_Branch = "Create_Branch",
    Create_Builder = "Create_Builder",
    Create_Client = "Create_Client",
    Create_Housing_Company = "Create_Housing_Company",
    Create_Housing_Company_Image = "Create_Housing_Company_Image",
    Create_Job = "Create_Job",
    Create_Lead = "Create_Lead",
    Create_Product = "Create_Product",
    Create_Product_Tag = "Create_Product_Tag",
    Create_Product_Price = "Create_Product_Price",
    Create_Role = "Create_Role",
    Create_Subscription = "Create_Subscription",
    Create_Supplier_Image = "Create_Supplier_Image",
    Create_Supplier = "Create_Supplier",
    Create_User_Identity = "Create_User_Identity",
    Create_User_Screen = "Create_User_Screen",
    Create_Firebase_Auth_Token = "Create_Firebase_Auth_Token",
    Create_Role_Screen = "Create_Role_Screen",
    Create_Files = "Create_Files",
    Create_Document = "Create_Document",
    Create_Document_Template = "Create_Document_Template",
    Create_Quote_Request = "Create_Quote_Request",
    Create_Webhook = "Create_Webhook",
    Delete_Branches = "Delete_Branches",
    Delete_Builders = "Delete_Builders",
    Delete_Clients = "Delete_Clients",
    Delete_Housing_Companies = "Delete_Housing_Companies",
    Delete_Housing_Company_Image = "Delete_Housing_Company_Image",
    Delete_Product_Tag = "Delete_Product_Tag",
    Delete_Product_Prices = "Delete_Product_Prices",
    Delete_Role = "Delete_Role",
    Delete_Supplier_Image = "Delete_Supplier_Image",
    Delete_Suppliers = "Delete_Suppliers",
    Delete_User = "Delete_User",
    Delete_User_Identity = "Delete_User_Identity",
    Delete_User_Screen = "Delete_User_Screen",
    Override_Purchase_Order_Status = "Override_Purchase_Order_Status",
    Delete_Role_Screen = "Delete_Role_Screen",
    Delete_Subscription = "Delete_Subscription",
    Delete_Files = "Delete_Files",
    Delete_Webhooks = "Delete_Webhooks",
    Unassign_User_Roles = "Unassign_User_Roles",
    Update_Branch = "Update_Branch",
    Update_Builder = "Update_Builder",
    Update_Client = "Update_Client",
    Update_Housing_Company = "Update_Housing_Company",
    Update_Job_Builder = "Update_Job_Builder",
    Update_Job_Client = "Update_Job_Client",
    Update_Job_Details = "Update_Job_Details",
    Update_Job_Site = "Update_Job_Site",
    Update_Job_Custom_Data = "Update_Job_Custom_Data",
    Update_Lead = "Update_Lead",
    Update_Role = "Update_Role",
    Update_Supplier_Reviewers = "Update_Supplier_Reviewers",
    Update_Product_Tag = "Update_Product_Tag",
    Update_User_Details = "Update_User_Details",
    Update_User_Identities = "Update_User_Identities",
    Update_User_Permissions = "Update_User_Permissions",
    Update_User_Screen = "Update_User_Screen",
    Update_Role_Screen = "Update_Role_Screen",
    Update_Webhook = "Update_Webhook",
    Query_Purchase_Orders = "Query_Purchase_Orders",
    Query_Lead = "Query_Lead",
    Query_Roles = "Query_Roles",
    Query_Upload_Request_Url = "Query_Upload_Request_Url",
    Query_Download_Request_Url = "Query_Download_Request_Url",
    Query_User_Identities = "Query_User_Identities",
    Query_Users = "Query_Users",
    Query_Housing_Companies = "Query_Housing_Companies",
    Query_Jobs = "Query_Jobs",
    Query_Leads = "Query_Leads",
    Query_Subscriptions = "Query_Subscriptions",
    Query_Branches = "Query_Branches",
    Query_Sales_Orders = "Query_Sales_Orders",
    Query_User_Screens = "Query_User_Screens",
    Query_Role_Screens = "Query_Role_Screens",
    Query_Quote_Requests = "Query_Quote_Requests",
    Query_Files = "Query_Files",
    Query_Record_Files = "Query_Record_Files",
    Query_Purchase_Order_Lines = "Query_Purchase_Order_Lines",
    Query_Product_Prices = "Query_Product_Prices",
    Query_Audit_Logs = "Query_Audit_Logs",
    Read_Branch_Name = "Read_Branch_Name",
    Read_Job_Custom_Data = "Read_Job_Custom_Data",
    Clear_Permission_Cache = "Clear_Permission_Cache"
}
export const PermissionConditionalPolicyInputTypes: Partial<Record<Permission, string>> = {
    [Permission.Update_Job_Custom_Data]: "JsonFilterInput",
    [Permission.Query_Purchase_Orders]: "PurchaseOrderFilterInput",
    [Permission.Query_Jobs]: "JobFilterInput",
    [Permission.Query_Leads]: "LeadFilterInput",
    [Permission.Query_Quote_Requests]: "QuoteRequestFilterInput",
    [Permission.Query_Files]: "FileFilterInput",
    [Permission.Query_Record_Files]: "RecordFileFilterInput",
    [Permission.Query_Audit_Logs]: "AuditLogFilterInput",
    [Permission.Read_Job_Custom_Data]: "JsonFilterInput"
};
export const PermissionDescriptions: Partial<Record<Permission, string>> = {
    [Permission.Disable_User]: "",
    [Permission.Enable_User]: "",
    [Permission.Create_User]: "",
    [Permission.Assign_Leads_To_Salesperson]: "",
    [Permission.Assign_User_Roles]: "",
    [Permission.Assign_User_Permissions]: "",
    [Permission.Bulk_Product_Import]: "",
    [Permission.Create_Branch]: "",
    [Permission.Create_Builder]: "",
    [Permission.Create_Client]: "",
    [Permission.Create_Housing_Company]: "",
    [Permission.Create_Housing_Company_Image]: "",
    [Permission.Create_Job]: "",
    [Permission.Create_Lead]: "",
    [Permission.Create_Product]: "",
    [Permission.Create_Product_Tag]: "",
    [Permission.Create_Product_Price]: "",
    [Permission.Create_Role]: "",
    [Permission.Create_Subscription]: "",
    [Permission.Create_Supplier_Image]: "",
    [Permission.Create_Supplier]: "",
    [Permission.Create_User_Identity]: "",
    [Permission.Create_User_Screen]: "",
    [Permission.Create_Firebase_Auth_Token]: "",
    [Permission.Create_Role_Screen]: "",
    [Permission.Create_Files]: "",
    [Permission.Create_Document]: "",
    [Permission.Create_Document_Template]: "",
    [Permission.Create_Quote_Request]: "",
    [Permission.Create_Webhook]: "",
    [Permission.Delete_Branches]: "",
    [Permission.Delete_Builders]: "",
    [Permission.Delete_Clients]: "",
    [Permission.Delete_Housing_Companies]: "",
    [Permission.Delete_Housing_Company_Image]: "",
    [Permission.Delete_Product_Tag]: "",
    [Permission.Delete_Product_Prices]: "",
    [Permission.Delete_Role]: "",
    [Permission.Delete_Supplier_Image]: "",
    [Permission.Delete_Suppliers]: "",
    [Permission.Delete_User]: "",
    [Permission.Delete_User_Identity]: "",
    [Permission.Delete_User_Screen]: "",
    [Permission.Override_Purchase_Order_Status]: "",
    [Permission.Delete_Role_Screen]: "",
    [Permission.Delete_Subscription]: "",
    [Permission.Delete_Files]: "",
    [Permission.Delete_Webhooks]: "",
    [Permission.Unassign_User_Roles]: "",
    [Permission.Update_Branch]: "",
    [Permission.Update_Builder]: "",
    [Permission.Update_Client]: "",
    [Permission.Update_Housing_Company]: "",
    [Permission.Update_Job_Builder]: "",
    [Permission.Update_Job_Client]: "",
    [Permission.Update_Job_Details]: "",
    [Permission.Update_Job_Site]: "",
    [Permission.Update_Job_Custom_Data]: "",
    [Permission.Update_Lead]: "",
    [Permission.Update_Role]: "",
    [Permission.Update_Supplier_Reviewers]: "",
    [Permission.Update_Product_Tag]: "",
    [Permission.Update_User_Details]: "",
    [Permission.Update_User_Identities]: "",
    [Permission.Update_User_Permissions]: "",
    [Permission.Update_User_Screen]: "",
    [Permission.Update_Role_Screen]: "",
    [Permission.Update_Webhook]: "",
    [Permission.Query_Purchase_Orders]: "",
    [Permission.Query_Lead]: "",
    [Permission.Query_Roles]: "",
    [Permission.Query_Upload_Request_Url]: "",
    [Permission.Query_Download_Request_Url]: "",
    [Permission.Query_User_Identities]: "",
    [Permission.Query_Users]: "",
    [Permission.Query_Housing_Companies]: "",
    [Permission.Query_Jobs]: "",
    [Permission.Query_Leads]: "",
    [Permission.Query_Subscriptions]: "",
    [Permission.Query_Branches]: "",
    [Permission.Query_Sales_Orders]: "",
    [Permission.Query_User_Screens]: "",
    [Permission.Query_Role_Screens]: "",
    [Permission.Query_Quote_Requests]: "",
    [Permission.Query_Files]: "",
    [Permission.Query_Record_Files]: "",
    [Permission.Query_Purchase_Order_Lines]: "",
    [Permission.Query_Product_Prices]: "",
    [Permission.Query_Audit_Logs]: "",
    [Permission.Read_Branch_Name]: "",
    [Permission.Read_Job_Custom_Data]: "",
    [Permission.Clear_Permission_Cache]: ""
};
